






import Vue from "vue";

export default Vue.extend({
  name: "add-bank-account-block" as string
})
