



































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import AddBankAccountModal from "@/components/modals/AddBankAccountModal.vue";
import AddPaymentCardModal from "@/components/modals/AddPaymentCardModal.vue";
import PaymentCardBlock from "@/components/payments/PaymentCardBlock.vue";
import BankAccountBlock from "@/components/payments/BankAccountBlock.vue";
import AddPaymentCardBlock from "@/components/payments/AddPaymentCardBlock.vue";
import AddBankAccountBlock from "@/components/payments/AddBankAccountBlock.vue";
import DeleteItemConfirmModal from "@/components/modals/DeleteItemConfirmModal.vue";
import BackButton from "@/components/back-button.vue";
import filterCircleIcon from "@iconify-icons/ion/filter-circle";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  components: {
    Layout,
    "add-payment-card-modal": AddPaymentCardModal,
    "add-bank-account-modal": AddBankAccountModal,
    "bank-account-block": BankAccountBlock,
    "payment-card-block": PaymentCardBlock,
    "add-payment-card-block": AddPaymentCardBlock,
    "add-bank-account-block": AddBankAccountBlock,
    "delete-item-confirm-modal": DeleteItemConfirmModal,
    "back-button": BackButton
  },

  data() {
    return {
      title: 'PAYMENT METHODS',
      icons: {
        home: filterCircleIcon,
      },
      currentPage: 1,
      perPage: 10
    }
  },

  async mounted(): Promise<void> {
    await this.setBankAccounts();
    await this.setPaymentCards();
  },

  computed: {
    ...mapGetters("paymentModule", {
      paymentCards: "GET_PAYMENT_CARDS",
      bankAccounts: "GET_BANK_ACCOUNTS"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      company: "GET_COMPANY",
    })
  },

  methods: {
    ...mapActions("paymentModule", {
      setPaymentCards: "FETCH_PAYMENT_CARDS",
      setBankAccounts: "FETCH_BANK_ACCOUNTS",
      removeBankAccount: "REMOVE_BANK_ACCOUNT",
      removePaymentCard: "REMOVE_PAYMENT_CARD"
    }),
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setPaymentCards();
          await this.setBankAccounts();
        }
      }
    }
  }
})
