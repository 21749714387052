






import Vue from "vue";

export default Vue.extend({
  name: "add-payment-card-block" as string
})
